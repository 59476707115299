@import '../../styles//variables.scss';

.sub-header-wrapper{
    background-color: $whiteColor;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 13px;
    padding-right: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0px;
    .title-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
            cursor: pointer;
        }
        .text{
            color: $primary;
            @include Roboto-Bold;
            font-size: 16px;
            // font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
        .arrow-text{
            color: $textColor !important;
        }
    }
    .action-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btns{
            width: 75px;
            height: 25px;
            border-radius: 50px;
            background-color: $whiteColor;
            @include Roboto-Regular;
            font-size: 13px;
            font-weight: 400;
        }
        .block-btn{
            button{
                border: 1px solid $primary;
                color: $primary;
            }
        }
        .delete-btn{
            button{
                border: 1px solid $deleteBTNColor;
                color: $deleteBTNColor;
                margin-left: 10px;
            }
        }
        .approve-btn{
            button{
                border: 1px solid #17b82c;
                color: #17b82c;
                margin-left: 10px;
            }
        }
    }
}