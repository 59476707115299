@import '../../styles/variables.scss';

.overlay-div{
    display: none;
}
.sidebar-wrapper{
    width: 300px;
    height: calc(100vh - 60px);
    position: absolute;
    top: 60px;
    left: 0;
    transition: width 0.3s, left 0.3s;
    z-index: 1050; //100000
    .sidebar-subwrapper{
        width: 100%;
        height: 100%;
        background-color: $sidebar-bg;
        .logo-wrapper{
            display: none;
        }
        .menu-list{
            width: 100%;
            height: calc(100vh - 60px);
            overflow-y: auto;
            background-color: $whiteColor;
            ul{
                padding: 0;
                margin: 0;
                padding-top: 10px;
                li{
                    display: flex;
                    border-left: 5px solid transparent;
                    font-size: 16px;
                    color: $primary;
                    font-weight: 500;
                    padding: 13px 35px;
                    outline: none;
                    align-items: center;
                    cursor: pointer;
                    @include Roboto-Medium;
                    &:hover {
                        background: $menu-hover;
                    }
                    .menu-icon {
                        display: inline-block;
                        min-width: 22px;
                        width: 22px;
                        height: 22px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-right: 17px;
                        line-height: 17px;
                    }
                    .logout-text{
                        color: $deleteBTNColor !important;
                    }
                }
                .selected-option{
                    font-weight: 600;
                    background-color: $active-menu;
                    border-left: 5px solid $primary;
                }   
                  
            }
            .logout-wrapper{
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 50px;
                .logout{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: $deleteBTNColor;
                    @include Roboto-Regular;
                    font-size: 16px;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                    text-align: left;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                    img{
                        margin-right: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px){
    .sidebar-wrapper{
        left: -300px;
        top: 0;
        height: 100vh;
        .sidebar-subwrapper{
            .logo-wrapper{
                cursor: pointer;
                width: 100%;
                height: 60px;
                padding-left: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background-color: $primary;
                img{
                  width: 175px;
                  height: 33px;
                }
            }
        }
    }
    .show-overlay{
        display: block !important;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1049; //99999
    }
    .open-sidebar{
        left: 0px !important;
    }
    .close-sidebar{
        left: -300px !important;
    }
}
