@import "../../styles/variables.scss";

.logoContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 36px;
}
.withTitle {
  margin-bottom: 12px !important;
}
.withOutSubtitle {
  margin-bottom: 40px !important;
}
.logoContainer img {
  // height: 200px;
  width: auto;
}
.title {
  color: $textColor;
  @include Roboto-Bold;
  font-size: 38px;
  // font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
}
.subTitle {
  color: $lightTextColor;
  @include Roboto-Regular;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 41px;
  white-space: pre-wrap;
}
