@import "../../styles/variables.scss";

.sub-header-wrapper {
  z-index: 1000;
  background-color: $whiteColor;
  min-height: 50px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 13px;
  padding-right: 20px;
  flex-wrap: wrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  .title-wrapper {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: flex-start;
    img {
      cursor: pointer;
    }
    span {
      color: $primary;;
      @include Roboto-Bold;
      font-size: 16px;
      // font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
    }
  }
  .action-wrapper {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    justify-content: flex-end;
    .dropdown {
      margin-right: 15px;
      padding: 10px 0px;
    }
    .dropdown-menu {
      width: 175px;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      border: 1px solid #f0f0f0;
      background-color: #ffffff;
    }
    .dropdown-item{
      color: $itemText;
      font-size: 15px;
    }
    .dropdown-item:active{
      color: $whiteColor;
    }

    .dropdown-label {
      color: $textColor;
      @include Roboto-Regular;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      padding-right: 9px;
    }
    .dropdown-toggle {
      color: $textColor;
      position: relative;
      text-align: left;
      width: 175px;
      height: 40px;
      padding-left: 20px;
      background-color: #f0f0f0;
      border-radius: 50px;
      font-size: 15px;

      .dropDownArrow {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
  }
}

.page-container {
  max-height: calc(100vh - 70px);
}
.search-container {
  position: relative;
  display: block;
  width: 283px;
  cursor: pointer;
  .ipad-fixes{
    display: none;
  }
  
  .clear-search {
    position: absolute;
    top: 50%;
    width: 13px;
    height: 13px;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    font-size: 15px;
  }
  .search-icon {
    position: absolute;
    top: 50%;

    transform: translateY(-50%);
    left: 21px;
  }
  @media screen and (max-width: 768px) {
    .ipad-fixes{
      display: inline-block;
    }
    .search-icon-user, .clear-search-user{
      top: 70%;
    }
  }
}
.search-background:focus{
  color: $textColor;
  background: #f0f0f0 !important;
  border: none !important;
}
.search-background {
  color: $textColor;
  cursor: pointer;
  background: #f0f0f0;
  border-radius: 20px;
  border-color: transparent;
  padding: 8px 30px 8px 57px;
  font-size: 15px;
  @include Roboto-Regular;

}

.table-conatiner {
  margin-top: 10px;
  background-color: $whiteColor;

  .table > :not(caption) > * > * {
    padding: 0px;
  }

  .table {
    position: relative;
    --bs-table-bg: #ffffff;
    --bs-table-accent-bg: $whiteColor;
    --bs-table-striped-color: $textColor;
    --bs-table-striped-bg: #f7f3ff;
    --bs-table-active-color: $textColor;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: $textColor;
    --bs-table-hover-bg: #e5d8ff;
    width: 100%;
    // margin-bottom: 1rem;
    margin-bottom: 0px;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    border-collapse: collapse;
    width: 100%;
    height: calc(100vh - 197px);
    thead {
      display: table;
      width: calc(100% - 3px);
      table-layout: fixed;
      tr {
        background: $whiteColor;
        border-bottom: 2px solid #e5d8ff !important;
      }
    }
    th {
      padding: 5px;
      text-align: left;
      font-size: 14px;
      color: $textColor;
      @include Roboto-Medium;
    }

    td {
      padding: 5px;
      font-size: 15px;
      color: $textColor;
      @include Roboto-Regular;
      text-align: left;
    }
    tr {
      vertical-align: middle;
      opacity: 1;
      height: 46px;
      // border-top: 1px solid #e5d8ff;
      // border-bottom: 1px solid #e5d8ff;
      &:nth-child(even){
        background-color: #f7f3ff;
      }
      &:hover{
        background-color: #e5d8ff;
      }
    }


    tbody {
      //border: 1px solid $borderColor;
      overflow-y: auto;
      display: block;
      //height: 100%;
      height: calc(100vh - 200px) !important;
      // padding-bottom: 3%;
      position: relative;
      background-color: $whiteColor;

      tr {
        display: table;
        width: 100%;

        table-layout: fixed;
        // background: white;
      }
      td {
        vertical-align: middle;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
@media screen and (max-width: 895px) {
  .table {
    height: calc(100vh - 248px) !important;
    #tbody{
      height: calc(100vh - 200px) !important;
    }
  }
}
@media screen and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .table {
    height: calc(100vh - 275px) !important;
    #tbody{
      height: calc(100vh - 270px) !important;
    }
  }
}
.row-hover:hover {
  cursor: pointer;
  .hover-button-container {
    display: block;
  }
}
.header-buttons {
  margin-right: 20px;
  img {
    cursor: pointer;
  }
}
.hover-button-container {
  position: relative;
  display: none;
  .hover-button-section {
    img {
      padding: 7px;
      cursor: pointer;
    }
  }
}
.role {
  text-transform: capitalize;
}
.genderBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-transform: capitalize;
  .gender {
    padding-right: 10px;
  }
  .approved{
    color: $approveColor !important;
  }
  .pending{
    color: $pendingColor !important;
  }
  .rejected{
    color: $rejectColor !important;
  }
}
// .hover-container:hover {
//   .hover-button-container {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: flex-end;
//   }

// }
.search-main-section {
  .unblock-icon-button,
  .block-icon-button {
    // position: static;
    margin: 6px 0 0 0;
  }
}
.sort-container {
  position: relative;
  padding-right: 12px;
  cursor: pointer;
  .sort-icon {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.body-checkbox {
  align-items: center;
  justify-content: flex-start;
  .checkbox > input:checked + span::before {
    top: 6px;
  }
}
.name-col {
  padding-left: 20px !important;
}

.header-checkbox {
  align-items: center;
  justify-content: flex-start;
  .checkbox > input:checked + span::before {
    top: 2px;
  }
}
.user-detail-container {
  display: flex;
  align-items: center;
}
.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-data-title {
  text-align: center;
  @include Roboto-Bold;
}
.text-center {
  text-align: center!important;
}