.common-wrapper {
  height: calc(100vh - 140px);
}

@media (max-width: 768px) {
  .common-wrapper {
    height: calc(100vh - 215px);
  }
}

ol,
ul,
pre,
p {
  padding: 0;
  margin: 0;
}

li {
  // &:nth-child(even) {
  //   background-color: #f7f3ff ;
  // }
  // &:nth-child(odd) {
  //   background-color: #fff ;
  // }
  background-color: #fff;
}

.job-list {
  li {
    &:nth-child(even) {
      background-color: #f7f3ff;
    }

    &:nth-child(odd) {
      background-color: #fff;
    }
  }
}

.job-parameter {
  color: #808080;
  font-family: "Roboto-Regular";
  font-size: 13px;
  font-weight: 400;
}

.job-parameter-value {
  color: #313131;
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 600;
  margin-right: 10px;
  letter-spacing: normal;
  line-height: normal;
  // height: 16px;
  // font-family: Montserrat;
}

.job-parameter-value-text {
  color: #313131;
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  // text-transform: lowercase;
  // text-transform:capitalize;
  // height: 16px;
  // font-family: Montserrat;
}

.job-parameter-value-text-role {
  color: #313131;
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  // text-transform: lowercase;
  // text-transform:capitalize;
  // height: 16px;
  // font-family: Montserrat;
}

div1 {
  text-transform: lowercase;
}

div1::first-letter {
  text-transform: uppercase;
}


.job-parameter-value-text-amount {
  color: #313131;
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 800;
  margin-left: 8px;
  // text-transform: lowercase;
  // height: 16px;
  // font-family: Montserrat;
}

.job-parameter-parent-container {
  display: flex;
  flex-wrap: wrap;
}

.job-parameter-container {
  display: flex;
  flex-wrap: wrap;
  padding-right: 29px;
  margin-bottom: 10px;
  width: 35.2%;
  // width: 50%;
  // align-items: center;
  // justify-content: flex-start;

}

.job-parameter-container2 {
  display: flex;
  // flex-wrap: wrap;
  padding-right: 29px;
  margin-bottom: 10px;


  // align-items: flex-start;
  // justify-content: flex-start;
  // justify-content:flex-start;
  // align-items: center;

}

.dropdown_btn {
  border-width: 1px;
  border: 1px solid#0f0643 !important;
  // display: flex;
  background-color: white;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 345px;
  border-radius: 5px;
  // text-align: start;
}

.dropdown_btn_icon {
  // padding-left: 100px;
  // margin: 10px;
}

.dropdown_btn_item {
  width: 410%;
  // background-color: white;

}

.job_post_text {
  font-size: 12px;
  margin-left: 10px;
}

.trip-details-link {
  color: #2600ff;
  font-family: "Roboto-Regular";
  font-size: 13px;
  font-weight: 400;
  padding-left: 5px;
}

.input-container {
  position: relative;
  padding-bottom: 30px;

  &:last-child {
    padding-bottom: 17px;
  }
}

.has-float-label {
  input {
    height: 40px;
    border: 1px solid #0f0643;
    font-size: 14px;
    // padding: 20px; 
  }
}

.form-group {
  input:focus {
    border: 1px solid#0f0643 !important;
  }
}


.job-list-container {
  .card-body {}

  margin-bottom: 2%;
  cursor: pointer;
  background-color: unset !important;

  &:nth-child(even) {
    background-color: #f7f3ff;
  }

  &:hover {
    background-color: #e5d8ff !important;

    .job-delete-icon {
      display: block;
    }

    .job-edit-icon {
      display: block;
    }
  }

}

.card-title {
  margin-bottom: 5px !important;
}

.card-subtitle {
  margin-top: 0 !important;
  margin-bottom: 6px;
}

.card-text {
  color: #000000;
  font-family: "Roboto-Regular";
  font-size: 13px;
  font-weight: 400;
}

.job-delete-icon {
  position: absolute;
  right: 16px;
  display: none;
  color: "#373a48"
}

.job-edit-icon {
  position: absolute;
  right: 60px;
  display: none;

}

.job-title {
  color: #000000;
  font-family: "Roboto-Medium";
  font-size: 15px;
  font-weight: 600;
  // font-family: "Montserrat";
  line-height: normal;
  letter-spacing: normal;
}

.job-title-subtitle {
  color: #000000;
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: 600;
  // font-family: "Montserrat";
  line-height: normal;
  letter-spacing: normal;

}

.job-company-name {
  color: #a08687;
  font-family: "Roboto-Medium";
  font-size: 13px;
  font-weight: 500;
}

.job-posted-date {
  color: #808080;
  font-family: "Roboto-Regular";
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 10px;
}

.no-hiring-data-wrapper {
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
}








#local,
#remote {
  margin: 0 auto;
  // width: 550px !important;
  // padding-top: 15px;
  height: 20px;
}

#float-label {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  position: relative;
}

#float-label input {
  width: 100%;
  height: 56px;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

#float-label label {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 12px;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

#float-label:focus-within label {
  transform: translate(0, 12px) scale(0.75);
}

#float-label .Active {
  transform: translate(0, 12px) scale(0.75);
}



.entity-update-modal {
  max-width: 500px !important;
  padding-top: 60px;

  .modal-content {
    // height: 660px !important;
    // height: 729px !important;
    border-radius: 5px !important;
    border: none !important;
  }

  .modal-body {
    padding: 0px;
    border-radius: 5px !important;

    .heading-wrapper {
      // background-color: $primary;
      // color: $whiteColor;
      background-color: #f9f9fb;
      text-align: center;
      position: relative;
      padding: 14px 15px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .heading-text {
        // color: $whiteColor;
        // @include Roboto-Regular;
        color: black;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }

      .close-div {
        color: black;
        position: absolute;
        top: 11px;
        right: 15px;
        cursor: pointer;
      }
    }

    .details-wrapper {
      padding: 25px 20px 20px 20px;

      .entity-file-details {
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        .data-file-text-wrapper {
          width: 45%;
          line-height: 17px;
          color: #000000;
          // @include Roboto-Regular;
          font-size: 14px;
          font-weight: 400;
        }

        .data-file-chip-wrapper {
          .data-chip {
            max-width: 100%;
            height: 40px;
            border-radius: 20px;
            border: 1px solid #e9e9f2;
            background-color: #f9f9ff;
            display: flex;
            align-items: center;
            padding: 0px 15px;

            .file-name {
              line-height: 17px;
              // color: $textColor;
              // @include Roboto-Regular;
              font-size: 14px;
              font-weight: 400;
              padding-right: 15px;
            }

            .chip-cross {
              cursor: pointer;
            }
          }
        }
      }

      .submit-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .solid-btn {
          height: 30px;
          border-radius: 15px;
          background-color: #6c4eff;
          ;
          border: 0px;
          // color: $whiteColor;
          // @include Roboto-Regular;
          font-size: 15px;
          line-height: 18px;
          font-weight: 500;
          padding: 0px 21px;
        }
      }
    }
  }
}














.text-field {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}

input {
  display: inline-block;
  border: thin solid #fafafa;
  border-bottom: solid medium #999;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

input:focus {
  // border: thin solid #32cd32;
  // border-bottom: solid medium #32cd32;
  background-color: #fff;
}

label {
  color: #999;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s;
}

input:focus~label,
input:valid~label {
  top: -10px;
  left: 15px;
  font-size: small;
  // color: #32cd32;
  background-color: #fff;
  padding: 0 5px 0 5px;
}

@media (max-width: 768px) {
  .no-hiring-data-wrapper {
    height: calc(100vh - 225px);
  }
}






.equal-division {
  display: flex;
  flex-direction: row;
  //  justify-content:flex-start;
  //  align-content: center; 
  margin-top: 10px;
  margin-bottom: 10px;

}

.wrap-in-row {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  justify-content: space-between;
}

.footer-division {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  //  align-content: flex-start; 
}

.bottom-plan-toggel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 510px;
  right: 10px;

  // 
  //  position:absolute;
}

.job-parameter-value-toggle {
  margin-right: 100px;
}

.seprate {
  width: 500px;
}

.activate-deactivate-text {
  color: #6c6c6c;
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 400;
  margin-right: 10px;
  letter-spacing: normal;
  line-height: 22%;
  text-align: center;
  bottom: 50px;
  width: 200px;
}

.equal-amount-division {
  display: flex;
  flex-direction: row;
}


.inputBox {
  position: relative;
  border-color: #0f0643;
  border-radius: 15px;
  width: 100%;
}

.inputBox select {
  // width: 300px;
  padding: 10px;
  font-size: 1rem;
}

.inputBox span {
  position: absolute;
  left: 0px;
  // right: 5px;
  margin: 10px;
  margin-top: 0px;
  // pointer-events: none;
  transition: 0.5s;
  // padding: 10px;
  padding-left: 10px;
  font-size: 12px;
  color: grey;
  background-color: white;
  // padding-bottom: 20px;
}

.inputBox p {
  position: absolute;
  left: 70px;
  bottom: 1px;
  // right: 5px;
  margin: 10px;
  margin-top: 0px;
  // pointer-events: none;
  transition: 0.5s;
  // padding: 10px;
  padding-left: 10px;
  font-size: 12px;
  
  
  // padding-bottom: 20px;
}


// .inputBox select ::after,

.dropdown1 {
  width: 100%;
  // padding: 20px;
  // background-color: #2600ff;
  margin-top: 12px;
  border-color: #0f0643;
  border-radius: 5px;
  // margin-bottom: 30px;
  border-width: 0.2px;
}

.typeDropdown{
  margin-bottom: 30px;
}

// .caret {
//   padding-right: 100px;
//   }

.dropdown  {
  color: black;
  // transform:translateX(10px) translateY(-7px);
  // padding-bottom: 20px;
  // background-color: #000000;
  font-size: 10px
}

.inputBox span ::after {
  position: absolute;
  left: 0px;
  pointer-events: none;
  transition: 0.5s;
  color: yellow;
  // transform:translateX(10px) translateY(-7px);
  // padding-bottom: 20px;
  font-size: 0.10rem;
}

