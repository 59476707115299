
.trip-management-container{
    #tbody{
        height: calc(100vh - 190px) !important;
    }
    table{
        margin-bottom: 0px !important;
    }
    @media screen and (max-width: 768px) {   
        #tbody{
            height: calc(100vh - 190px) !important;
        }
        table{
            margin-bottom: 0px !important;
        }
    }
}