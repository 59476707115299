@import '../../styles/variables.scss';

.masterdata-wrapper{
    // display: flex;
    background-color: $page-bg;
    height: 100%;
    .masterdata-sub-wrapper{
        .note-wrapper {
            height: 57px;
            display: flex;
            align-items: center;
            justify-content: center;
            .note-text{
                color: #808080;
                font-family: 'Roboto-Regular';
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
            }
        }
        .entity-list-wrapper{
            width: 100%;
            max-height: calc(100vh - 187px);
            overflow-y: auto;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);  // 1fr 1fr 1fr;
            grid-gap: 10px;
            @media screen and (max-width: 768px) {
                grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr); //1fr 1fr;
            }
            .entity-card{
                width: 100%;
                background-color: #fff;
                .entity-card-header{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 50px;
                    width: 100%;
                    padding: 0px 15px;
                    border-bottom: 1px solid #e3e7f4;
                    .entity-name{
                        color: #1a1a1a;
                        font-family: 'Roboto-Regular';
                        font-size: 15px;
                        font-weight: 700;
                        max-width: calc(100% - 35px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .download-icon{
                        cursor: pointer;
                    }
                }
                .entity-card-body{
                    padding: 15px;
                    display: flex;
                    justify-content: space-between;
                    .updated-info{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .check-icon{
                            margin-right: 10px;
                            height: 18px;
                        }
                        .uncheck-icon{
                            margin-right: 10px;
                            height: 12px;
                        }
                        .info-text{
                            color: #1a1a1a;
                            font-family: 'Roboto-Regular';
                            font-size: 14px;
                            font-weight: 400;
                            padding-right: 10px;
                        }
                    }
                    .update-btn-wrapper{
                        display: flex;
                        align-items: center;
                        .outline-btn{
                            height: 30px;
                            border-radius: 52px;
                            border: 1px solid $primary;
                            color: $primary;
                            @include Roboto-Regular;
                            font-size: 13px;
                            font-weight: 400;
                            background-color: $whiteColor;
                            padding: 0px 15px;
                          }
                    }
                }
            }
        }
        .no-data-wrapper{
            width: 100%;
            height: calc(100vh - 130px);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}


.entity-update-modal{
    max-width: 400px !important;
    .modal-content{
        border-radius: 5px !important;
        border: none !important;
    }
    .modal-body{
        padding: 0px;
        border-radius: 5px !important;
        .heading-wrapper{
            background-color: $primary;
            color: $whiteColor;
            text-align: center;
            position: relative;
            padding: 14px 15px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            .heading-text{
                color: $whiteColor;
                @include Roboto-Regular;
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
            }
            .close-div{
                position: absolute;
                top: 11px;
                right: 15px;
                cursor: pointer;
            }
        }
        .details-wrapper{
            padding: 25px 20px 20px 20px;
            .entity-file-details{
                display: flex;
                align-items: center;
                padding-bottom: 30px;
                .data-file-text-wrapper{
                    width: 45%;
                    line-height: 17px;
                    color: #000000;
                    @include Roboto-Regular;
                    font-size: 14px;
                    font-weight: 400;
                }
                .data-file-chip-wrapper{
                    .data-chip{
                        max-width: 100%;
                        height: 40px;
                        border-radius: 20px;
                        border: 1px solid #e9e9f2;
                        background-color: #f9f9ff;
                        display: flex;
                        align-items: center;
                        padding: 0px 15px;
                        .file-name{
                            line-height: 17px;
                            color: $textColor;
                            @include Roboto-Regular;
                            font-size: 14px;
                            font-weight: 400;
                            padding-right: 15px;
                        }
                        .chip-cross{
                            cursor: pointer;
                        }
                    }
                }
            }
            .submit-btn-wrapper{
                display: flex;
                align-items: center;
                justify-content: center;
                .solid-btn{
                    height: 30px;
                    border-radius: 15px;
                    background-color: #a58b89;;
                    border: 0px;
                    color: $whiteColor;
                    @include Roboto-Regular;
                    font-size: 15px;
                    line-height: 18px;
                    font-weight: 500;
                    padding: 0px 21px;
                }
            }
        }
    }   
}