
.user-reported-container{
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }
  .common-wrapper {
    background-color: #eef1ed;
  }
  .delete-btn {
    button {
      border: 1px solid #ff0000;
      color: #ff0000;
      margin-left: 10px;
      border-radius: 50px;
      width: 75px;
      height: 25px;
      border-radius: 50px;
      background-color: #ffffff;
      font-family: "Roboto-Regular";
      font-size: 13px;
      font-weight: 400;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
  .comment-tab {
    padding: 13px 17px 12px 17px;
    background-color: #efeef3;
    color: #000000;
    font-family: "Roboto-Regular";
    font-size: 13px;
    font-weight: 400;
  }
  .comment-card {
    padding: 10px 17px 10px 17px;
    background-color: #ffffff;
    display: flex;
    border-bottom: 1px solid #e3e7f4;
  }
  .comment-title {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
    h5 {
      color: #000000;
      font-family: "Roboto-Medium";
      font-size: 13px;
      font-weight: 500;
      margin-right: 9px;
    }
    h6 {
      font-family: "Roboto-Regular";
      color: #808080;
      font-size: 11px;
      font-weight: 400;
    }
  }
  .comment-description {
    color: #000000;
    font-family: "Roboto-Regular";
    font-size: 11px;
    font-weight: 400;
  }
  .comment-img {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    margin-right: 10px;
  }
}
