@import "../../styles/variables.scss";
.common-wrapper {
  padding-top: 10px;
  .common-info-card {
    background-color: $whiteColor;
    .info-card-header {
      background-color: #ebebeb;
      color: #0f0643;
      @include Roboto-Bold;
      font-size: 17px;
      line-height: 21px;
      padding: 12px 17px;
      border-bottom: 1px solid #cfcfcf;
    }
    .info-card-body {
      padding: 17px 17px 20px 17px;
      border-bottom: 1px solid #cfcfcf;
      width: 100%;
      .table-container-crew-members {
        columns: 2 auto;
        row-gap: 16px;
      }
      .info-row {
        width: 100%;
        padding-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        .info-col {
          width: 25%;
          .key-value-pair {
            display: flex;
            .key-text,
            .value-text {
              @include Roboto-Regular;
              font-size: 13px;
              font-weight: 400;
              line-height: 18px;
            }
            .key-text {
              color: $borderColor;
            }
            .value-text {
              color: $textColor;
              padding-left: 5px;
            }
            .phone-text {
              color: #0f0643;
              @include Roboto-Medium;
              font-size: 13px;
              font-weight: 500;
              font-style: normal;
              letter-spacing: normal;
              line-height: 18px;
            }
            .shorthand {
              color: #808080;
              @include Roboto-Medium;
              font-size: 13px;
              font-style: italic;
              margin-left: 4px;
            }
          }
        }
      }
      .note-text-wrapper {
        color: $textColor;
        @include Roboto-Regular;
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 18px;
      }
      .fight-leg-item {
        padding: 20px 17px;
        display: flex;
        width: 100%;
        .legitem-number-section {
          color: #988ba8;
          @include Roboto-Medium;
          font-size: 25px;
          font-weight: 500;
          line-height: 18px;
        }
        .leg-info-section {
          width: 100%;
          padding-left: 30px;
          .leg-col {
            width: 50% !important;
          }
          .info-row:last-child {
            padding: 0px !important;
          }
        }
      }
      .fight-leg-item:not(:last-child) {
        border-bottom: 1px solid #e3e7f4;
      }
      .col-50 {
        width: 50% !important;
      }
      .retal-info-row {
        padding-bottom: 5px !important;
      }
    }
  }
}
