.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image:url(../../assets/img/bg-min.png);
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% 100%;  // cover
  position: absolute;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
