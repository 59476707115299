// Override default variables before the import
$body-bg: #000;
$primary: #0f0643;
$primaryRippleColor: #443D6C;
$lightRippleColor: #dae0e5;
$borderColor: #808080;
$danger: #b00020;
$textColor: #000000;
$itemText: #282828;
$lightTextColor: #808080;
$anchorColor: #313131;
$whiteColor: #ffffff;
$tableStripeColor: #f7f3ff;
$tableHoverColor: #e5d8ff;
$rejectColor: #ff0000;
$pendingColor: #ffa500;
$approveColor: #17b82c;
$deleteBTNColor: $rejectColor;
$page-bg: #efeef3;
$sidebar-bg: #f9f9ff;
$active-menu: #f2e8fe;
$menu-hover: #f3edfa;

$pdf-color: #f15642;
$fileBorderColor: #eeeeee;
$file-bg: #f8f8f8;

/* Font Family */
@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url(../assets/fonts/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(../assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(../assets/fonts/Roboto-Bold.ttf) format("truetype");
}

@mixin Roboto-Regular {
  font-family: "Roboto-Regular", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin Roboto-Medium {
  font-family: "Roboto-Medium", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin Roboto-Bold {
  font-family: "Roboto-Bold", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}
