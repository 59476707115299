
  .common-wrapper{
    height: calc(100vh - 140px);
  }
  @media (max-width: 768px) {
    .common-wrapper{
      height: calc(100vh - 215px);
    }
  }
  ol,
  ul,
  pre,
  p {
    padding: 0;
    margin: 0;
  }
  li{
    // &:nth-child(even) {
    //   background-color: #f7f3ff ;
    // }
    // &:nth-child(odd) {
    //   background-color: #fff ;
    // }
    background-color: #fff ;
  }

  .job-list{
    li{
      &:nth-child(even) {
        background-color: #f7f3ff ;
      }
      &:nth-child(odd) {
        background-color: #fff ;
      }
    }
  }
  
  .job-parameter {
    color: #808080;
    font-family: "Roboto-Regular";
    font-size: 13px;
    font-weight: 400;
  }
  .job-parameter-value {
    color: #000000;
    font-family: "Roboto-Regular";
    font-size: 13px;
    font-weight: 400;
  }
  .job-parameter-parent-container{
    display: flex;
    flex-wrap: wrap;
  }
  .job-parameter-container {
    display: flex;
    flex-wrap: wrap;
    padding-right: 29px;
    margin-bottom: 10px;
  }
  .trip-details-link {
    color: #2600ff;
    font-family: "Roboto-Regular";
    font-size: 13px;
    font-weight: 400;
    padding-left: 5px;
  }
  .job-list-container {
    .card-body{
    }
      cursor: pointer;
      background-color: unset !important;
      &:nth-child(even) {
        background-color: #f7f3ff ;
      }
      &:hover {
        background-color: #e5d8ff !important;
        .job-delete-icon{
          display: block;
        }
      }
    
  }
  
  .card-title {
    margin-bottom: 5px !important;
  }
  .card-subtitle {
    margin-top: 0 !important;
    margin-bottom: 6px;
  }
  .card-text {
    color: #000000;
    font-family: "Roboto-Regular";
    font-size: 13px;
    font-weight: 400;
  }
  .job-delete-icon {
    position: absolute;
    right: 16px;
    display: none;
  }
  .job-title {
    color: #000000;
    font-family: "Roboto-Medium";
    font-size: 16px;
    font-weight: 500;
  }
  .job-company-name {
    color: #a08687;
    font-family: "Roboto-Medium";
    font-size: 13px;
    font-weight: 500;
  }
  .job-posted-date {
    color: #808080;
    font-family: "Roboto-Regular";
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .no-hiring-data-wrapper{
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    .no-hiring-data-wrapper{
      height: calc(100vh - 225px);
    }
  }
