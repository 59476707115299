@import "./variables.scss";
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import url("https://cdn.rawgit.com/tonystar/bootstrap-float-label/v4.0.2/bootstrap-float-label.min.css");


a {
  color: inherit;
  text-decoration: none !important;
}
a:hover{
  color: $primaryRippleColor;
  text-decoration: none;
  opacity: 0.8;
}

body {
  font-family: "Roboto", sans-serif;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  
  outline: none;
  box-shadow: none;
}

/* Ripple effect */
.ripple {
  background-position: center !important;
  transition: background 0.8s;
}
.ripple:hover {
  background: $primaryRippleColor
    radial-gradient(circle, transparent 1%, $primary 1%) center/15000% !important;
}
.ripple:active {
  background-color: $primaryRippleColor !important;
  background-size: 100% !important;
  transition: background 0s !important;
}

/* Ripple effect */
.ripple-light {
  background-position: center !important;
  transition: background 0.8s;
}
.ripple-light:hover {
  // color: $labelColor !important;
  background: $lightRippleColor radial-gradient(circle, transparent 1%, $whiteColor 1%)
    center/15000% !important;
  border-color: $lightRippleColor !important;
}
.ripple-light:active {
  // color: $labelColor !important;
  background-color: $lightRippleColor !important;
  background-size: 100% !important;
  transition: background 0s !important;
  border-color: $lightRippleColor !important;

}

/* Ripple light for update button in master data effect */
.ripple-light-ms-update {
  background-position: center !important;
  transition: background 0.8s;
}
.ripple-light-ms-update:hover {
  // color: $labelColor !important;
  background: $lightRippleColor radial-gradient(circle, transparent 1%, $whiteColor 1%)
    center/15000% !important;
  border-color: $lightRippleColor !important;
  color: $textColor !important;
}
.ripple-light-ms-update:active {
  // color: $labelColor !important;
  background-color: $lightRippleColor !important;
  background-size: 100% !important;
  transition: background 0s !important;
  border-color: $lightRippleColor !important;

}

/***** Common css ****/
.has-float-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: medium !important;
  top: 50% !important;
  transform: translateY(-50%);
  color: $borderColor;
  @include Roboto-Regular;
  font-size: 14px !important;
  font-weight: 400;
  font-style: normal;
}
.has-float-label label,
.has-float-label > span {
  color: $borderColor;
  @include Roboto-Regular;
  font-size: 11px; // 14
  font-weight: 400;
  font-style: normal;
  background-color: $whiteColor;
}
// .has-float-label input:not(:placeholder-shown) + label {
//   color: $primary !important;
// }
// for focussed label
.form-group {
  input:focus + label,
  input:focus + span {
    color: $primary !important;
  }
}
// for focuessed input
.form-group {
  input:focus {
    border: 1px solid $primary !important;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  margin-bottom: 0px !important;
  background-image: none !important;
  padding-right: 5px !important;
 
}
.form-control.is-invalid + label,
.form-control.is-invalid + span {
  color: $danger !important;
}
input.form-control:focus + span {
  color: $primary !important;
}
input.form-control:focus + span {
  border: 1px solid $primary !important;
}
.has-float-label {
  input {
    height: 40px;
    border: 1px solid $borderColor;
    font-size: 14px;
  }
}
.input-container {
  position: relative;
  padding-bottom: 30px;
  &:last-child{
    padding-bottom: 17px;
  }
}
input[type=password], input[type=text]{
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  &::-ms-reveal {
    display: none !important;
  }
  &::-ms-clear {
    display: none !important;
  }
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden !important;
  }
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden !important;
    display: none !important;
    pointer-events: none !important;
    position: absolute !important;
    right: 0 !important;
  }
}

.error {
  position: absolute;
  bottom: 12px;
  color: $danger;
  @include Roboto-Regular;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

* {
  box-sizing: border-box;
}
.checkbox-container-rem {
  position: relative;
  border-top: none !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;

  .unchecked-remember{
    color: #4d4d4d;
  }
}
.login-checkbox{
  margin-top: -13px;
}

.vertical-unset {
  vertical-align: unset !important;
}

.checkbox {
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
}

.checkbox > span {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  padding-left: 15px;
  vertical-align: middle;
  display: inline-block;
}
.header-checkbox .checkbox-container-rem .checkbox > input {
  border: 1px solid #ffffff;
}
.checkbox > input {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 2px solid $borderColor;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: transparent;
  cursor: pointer;
}
#checkbox {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  // display: inline-block;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // -o-appearance: none;
  // appearance: none;
  border: 2px solid $borderColor;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  // background-color: transparent;
  background-color: $primary;

  cursor: pointer;
}
#checkbox:checked {
  border: 1px solid $primary;
  background-color: $primary;
  
}
.checkbox > input:checked {
  border: 1px solid $primary;
  background-color: $primary;
}

.checkbox > input:checked + span::before {
  content: "\2713";
  display: block;
  text-align: center;
  color: #ffffff;
  font-weight: bolder;
  position: absolute;
  left: 4px;
  // top: 1px;
}

.checkbox > input:active {
  border: 2px solid $primary;
}
#checkbox:active {
  border: 2px solid $primary;
  
}

.loginBtn {
  background-color: $primary;
  height: 45px;
  border-radius: 50px;
  border: 1px solid $borderColor;
  color: $whiteColor;
  @include Roboto-Medium;
  font-size: 16px;
  font-weight: 500;
}
.backToSignIn {
  margin: 0px;
  margin-top: 30px;
  font-size: 16px;
  @include Roboto-Regular;
  font-weight: 400;

  color: $anchorColor;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  a {
    color: $primary;
    @include Roboto-Medium;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    padding-left: 5px;
    cursor: pointer;
  }
}
.otp-input-container {
  position: relative;
  padding-bottom: 20px;
  .error {
    position: absolute;
    bottom: 2px;
  }
}
.otp-container {
  justify-content: center;
  // margin-bottom: 20px;
}
.otp-input {
  margin: 0 15px;
  width: 40px !important;
  height: 40px;
  border-radius: 5px;
  border: 1px solid $lightTextColor;
}
.on-focus {
  // border: 1px solid $lightTextColor;
}
.otp-invalid {
  border: 1px solid $danger !important;
}
.resend-otp-container {
  width: 390px;
  margin-left: auto;
  margin-right: auto;
  .resend-OTP {
    width: max-content;
    margin-left: auto;
    color: $primary;
    @include Roboto-Medium;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
}

.forgot-text{
  @include Roboto-Medium;
  font-size: 14px;
}

.common-wrapper{
  height: calc(100vh - 130px);
  overflow-y: auto;
}

/*-------------- Confirm Popup ---------------*/
.confirm-alert{
  width: 320px !important;
  .modal-content{
    border-radius: 10px !important;
    border: none !important;
    .modal-body{
      padding: 27px 20px 20px 20px !important;
      .confirm-text{
        color: $textColor;
        @include Roboto-Regular;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 18px;
      }
      .btn-div{
        display: flex;
        justify-content: flex-end;
        padding-top: 35px;
        .outline-btn{
          width: 86px;
          height: 30px;
          border-radius: 52px;
          border: 1px solid $primary;
          color: $primary;
          @include Roboto-Regular;
          font-size: 13px;
          font-weight: 400;
          background-color: $whiteColor;
        }
        .danger-btn{
            border-color: #ff0000 !important;
            color: #ff0000 !important;
        }
        .success-btn{
            border-color: #17b82c !important;
            color: #17b82c !important;
        }
        .solid-btn{
          width: 86px;
          height: 30px;
          border-radius: 52px;
          background-color: $primary;
          border: 0px;
          color: $whiteColor;
          @include Roboto-Regular;
          font-size: 13px;
          font-weight: 400;
          margin-left: 10px;
        }
      }
    }
  }
}

/*-------------- PDF Popup ---------------*/
.pdf-viewer{
  width: 100vw !important; //80vw
  height: 100vh !important; //80vh
  max-width: 100vw !important; //80vw
  margin: 0px;
  .modal-content{
    width: 100%;
    height: 100%;
    // border-radius: 10px;
    background-color: transparent;
    .modal-header{
      border: none;
      // padding-bottom: 0px;
      line-height: 20px;
      background-color: rgba(0,0,0,0.5);
      .back-arrow{
        cursor: pointer;
      }
      .header-text{
        @include Roboto-Bold;
        font-size: 17px;
        font-weight: 700;
        // color: $primary;
        color: #fff;
      }
      button{
        display: none;
        background-color: #ffffff;
        border: none;
        font-size: 30px;
        line-height: 18px;
      }
    }
    .modal-body{
      padding: 0px;  // change
      embed{
        width: 100%;
        // height: 100%;
        height: calc(100vh - 62px); // fixed for safari browser issue.
        z-index: 1;
      }
      .loading-background{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: #fff;
        font-size: 20px;
        @include Roboto-Bold;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
      }
    }
  }
}

/*------ loading-Spinner css ------------*/
.loading-overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1051;
}

/*-------text-colors -------*/
.approve-text{
  color: #17b82c;
}
.pending-text{
  color: #ffa500;
}
.rejected-text{
  color: #a58b89;
}
.blocked-text{
  color: #ff1200;
}
.reported-text{
  color: #0f0643;
}

.no-margin {
  margin: 0px !important;
}
.resend-timer-wrapper{
  display: flex;
  width: 390px;
  margin: auto;
}
.opacity-50{
  opacity: 0.5;
}

.action-wrapper-common{
  width: 50%;
}

// common css classes
.no-padding{
  padding: 0px !important;
}

/*--------------OLD PDF Popup ---------------*/
// .pdf-viewer{
//   width: 80vw !important;
//   height: 80vh !important;
//   max-width: 80vw !important;
//   .modal-content{
//     width: 100%;
//     height: 100%;
//     border-radius: 10px;
//     .modal-header{
//       border: none;
//       padding-bottom: 0px;
//       line-height: 20px;
//       .header-text{
//         @include Roboto-Bold;
//         font-size: 17px;
//         font-weight: 700;
//         color: $primary;
//       }
//       button{
//         background-color: #ffffff;
//         border: none;
//         font-size: 30px;
//         line-height: 18px;
//       }
//     }
//     .modal-body{
//       embed{
//         width: 100%;
//         height: 100%;
//       }
//     }
//   }
// }