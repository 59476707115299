@import '../../styles/variables.scss';

.info-card-wrapper{
    width: 100%;
    .about-div-margin{
        margin: 10px 0px;
    }
    .info-card{
        .info-card-title{
            background-color: $tableStripeColor;
            color: $primary;
            @include Roboto-Bold;
            font-size: 17px;
            // font-weight: 700;
            padding: 13px 17px;
            line-height: 20px;
        }
        .bg-white{
            background-color: $whiteColor !important;
        }
        .info-card-body{
            background-color: $whiteColor;
            .text-list{
                padding: 10px 17px 1px 17px;
                ul{
                    list-style-type: none;
                    padding-left: 0px;
                    li{
                        color: $textColor;
                        @include Roboto-Medium;
                        font-size: 15px;
                        line-height: 20px;
                    }
                }
            }
            .detail-list{
                padding: 10px 17px 12px 17px;
                .detail-row:not(:last-child){
                    border-bottom: 1px solid #efeef3;
                    margin-bottom: 15px;
                }
                .aircraft-info-details{
                    border-bottom: none !important;
                    width: 50%;
                    .row-div{
                        min-width: 250px;
                        padding-bottom: 0px !important;
                    }
                }
                .row-div{
                    display: flex;
                    justify-content: flex-start;
                    padding-bottom: 8px;
                    .key-text{
                        color: $lightTextColor;
                        @include Roboto-Medium;
                        font-size: 15px;
                        font-weight: 500; 
                        min-width: 90px;
                    }
                    .key-text-deg{
                        min-width: 200px;
                    }
                    .key-text-exp{
                        min-width: 125px;
                    }
                    .value-text{
                        color: $textColor;
                        @include Roboto-Medium;
                        font-size: 15px;
                        font-weight: 500;
                        padding: 0px 40px 0px 15px;
                    }
                }
                .file-details{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 105px;
                    padding-bottom: 8px;
                    .file-placeholder{
                        width: 50px;
                        height: 50px;
                        background-color: $pdf-color;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        color: $whiteColor;
                        cursor: pointer;
                        @include Roboto-Regular;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .file-info{
                        max-width: 294px;
                        min-width: 150px;
                        height: 50px;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;;
                        border: 1px solid $fileBorderColor;
                        background-color: $file-bg;
                        padding: 5px 16px;
                        cursor: pointer;
                        .file-name{
                            color: $textColor;
                            @include Roboto-Medium;
                            font-size: 15px;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; 
                        }
                        .file-size{
                            color: $lightTextColor;
                            @include Roboto-Medium;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 18px;
                            text-transform: uppercase;
                            width: 100%;
                        }
                    }
                }
                .file-deg{
                    padding-left: 215px !important;
                }
            }
            .aircraft-type-list{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                padding-bottom: 10px;
            }
            .about-text{
                padding: 10px 17px 20px 17px;
                color: $textColor;
                @include Roboto-Medium;
                font-size: 15px;
                font-weight: 500;
            }
        }
    }
}