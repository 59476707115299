body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
  background-color: #ffffff !important;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 767px) {
  #root {
    display: none;
  }
  .not-support {
    display: block !important;
    text-align: center;
    padding-top: 50px;
    margin: 15px;
  }
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1) !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

* {
  scrollbar-width: thin;  // for fixfox
}

.width-40{
  width: 40%;
}
.width-25{
  width: 25%;
}
.width-225{
  width: 22.5%;
}
.width-20{
  width: 20%;
}
.width-15{
  width: 15%;
}
.width-125{
  width: 12.5%;
}
.width-10{
  width: 10%;
}