@import "../../styles/variables.scss";

.layout-wrapper {
  .page-wrapper {
    margin-top: 60px; //60px
    padding-top: 10px;
    padding-left: 310px; //300px
    padding-right: 10px;
    padding-bottom: 10px;
    // width: calc(100vw - 300px);
    // height: calc(100vh - 70px);
    // max-height: calc(100vh - 70px);
    overflow: auto;

    width: calc(100vw);
    //height: 100vh;
    height: calc(100vh - 60px);
    background-color: #efeef3;
    background-color: $page-bg;
  }
  .no-auth-pages {
    margin-top: 0px !important;
    padding: 0px !important;
    height: 100vh !important;
    width: 100vw !important;
    background: transparent;
  }
}

@media screen and (max-width: 1024px) {
  .layout-wrapper {
    .page-wrapper {
      padding-left: 10px; //0px
      width: calc(100vw);
      overflow: auto;
    }
  }
}
