@import '../../styles/variables.scss';

.user-details-wrapper{
    .personal-info-wrapper{
        margin: 10px 0px;
        background-color: $whiteColor;
        .title{
            color: $primary;
            @include Roboto-Bold;
            font-size: 17px;
            // font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            padding: 15px 17px;
        }
        .info-container{
            display: flex;
            .profile-pic-section{
                padding: 10px 17px;
                .profile-pict{
                    width: 150px;
                    height: 150px;
                    background-image: url('../../assets/img/userlist_default_picture.svg');
                    background-size: cover;
                    img{
                        width: 100%;
                        height: 100%;
                        margin-right: 0px;
                        margin-right: 3px !important;
                    }
                }
                .user-status{
                    text-align: center;
                    padding-top: 10px;
                    @include Roboto-Regular;
                    font-size: 13px;
                    font-weight: 400;
                }
                .approve-text{
                    color: #17b82c;
                }
                .pending-text{
                    color: #ffa500;
                }
                .rejected-text{
                    color: #a58b89;
                }
                .blocked-text{
                    color: #ff1200;
                }
                .reported-text{
                    color: #0f0643;
                }
            }
            .profile-info-section{
                padding-top: 10px;
                width: 100%;
                .row-div{
                    display: flex;
                    justify-content: flex-start;
                    padding-bottom: 8px;
                    .key-text{
                        color: $lightTextColor;
                        @include Roboto-Medium;
                        font-size: 15px;
                        font-weight: 500; 
                        min-width: 90px;
                    }
                    .value-text{
                        color: $textColor;
                        @include Roboto-Medium;
                        font-size: 15px;
                        font-weight: 500;
                        padding: 0px 40px 0px 15px;
                    }
                }
                .social-section{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 7px;
                    margin-bottom: 20px;
                    .social-item{
                        padding-right: 15px;
                        a{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}